import React, { useContext } from "react";
import { Formik } from "formik";
import { subYears } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { updateConsultation } from "../database/consultations";
import InputGroup from "../components/shared/fields/InputGroup";
import StyledForm from "../components/StyledForm";
import { WhitelabelContext } from "../contexts";

export default function GrownUpForm({
  grownUp,
  id,
  onSubmit,
  onDelete,
  ActionsComponent,
}) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const navigate = useNavigate();
  const whiteLabeler = useContext(WhitelabelContext);

  const { path = "" } = whiteLabeler || {};

  const schema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("Too Short!"))
      .max(50, t("Too Long!"))
      .required(t("Required")),
    lastName: Yup.string().min(2, t("Too Short!")).max(50, t("Too Long!")),
    birthDate: Yup.date()
      .max(subYears(new Date(), 18), t("You must be at least 18 years old."))
      .required(t("Required")),
    residentSince: Yup.string().required(t("Required")),
    residencyDate: Yup.date().when("residentSince", {
      is: "birth",
      then: Yup.date().notRequired(),
      otherwise: Yup.date().required(),
    }),
    gender: Yup.string().required(t("This field is required")),
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        birthDate: "",
        residencyDate: "",
        residentSince: "",
        gender: "",
        ...grownUp, // Use values from context.
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async values => {
        // Update the existing customer or create a new one:
        const firestore = getFirestore();
        const customerExists = Boolean(id);
        const data = {
          ...values,
          type: "grownUp",
          modified: serverTimestamp(),
          residencyDate:
            values.residentSince === "birth"
              ? values.birthDate
              : values.residencyDate,
        };
        if (customerExists) {
          const ref = doc(firestore, "customers", id);
          await updateDoc(ref, data);
        } else {
          const ref = await addDoc(collection(firestore, "customers"), {
            ...data,
            created: serverTimestamp(),
          });
          // Add to consultation doc:
          await updateConsultation(consultationID, {
            "customers.grownUps": arrayUnion(ref.id),
          });

          if (
            window.location.pathname ===
            `${path}/consultations/${consultationID}/people`
          ) {
            navigate(`/consultations/${consultationID}/wizard/income`);
          }
        }
        onSubmit();
      }}
    >
      {({ values, dirty }) => (
        <StyledForm
          ActionsComponent={ActionsComponent}
          dirty={dirty}
          onDelete={onDelete}
        >
          <InputGroup
            name="firstName"
            type="text"
            label={t("First Name")}
            description={t(
              "We need this so that the software can be nice to you.",
            )}
          />
          <InputGroup name="lastName" type="text" label={t("Last Name")} />
          <InputGroup
            label="Birthday"
            type="date"
            name="birthDate"
            description={t(
              "We need this to calculate when you will probably retire.",
            )}
          />
          <InputGroup
            as="select"
            name="residentSince"
            label={t("In Switzerland since")}
            description={t(
              "We need this to find out since when you’re paying AHV.",
            )}
          >
            <option value="">{t("Please choose")}</option>
            <option value="birth">{t("Since birth")}</option>
            <option value="later">{t("Later")}</option>
          </InputGroup>
          {values.residentSince === "later" && (
            <InputGroup type="date" name="residencyDate" />
          )}
          <InputGroup
            as="select"
            name="gender"
            label={t("Gender")}
            description={t(
              "We need this to find out how much the AHV will pay you.",
            )}
          >
            <option value="">---</option>
            <option value="m">{t("Male")}</option>
            <option value="f">{t("Female")}</option>
          </InputGroup>
        </StyledForm>
      )}
    </Formik>
  );
}
