import React, { useContext, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import classNames from "classnames";
import StyledErrorMessage from "./shared/fields/StyledErrorMessage";
import { XCircleIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { WhitelabelContext } from "../contexts";

export default function Newsletter() {
  const { t } = useTranslation();
  const whiteLabeler = useContext(WhitelabelContext);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const { name } = whiteLabeler || {};

  return (
    <section className={`${whiteLabeler ? `bg-${name}-dark` : "bg-brand"}`}>
      <div className="container mx-auto flex flex-col space-y-2 items-center py-16 text-center p-6">
        <h2 className="text-white md:w-1/2 mb-5">
          {t("Subscribe to our newsletter to receive the latest updates")}
        </h2>
        <Formik
          enableReinitialize
          initialValues={{ email: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required().label("Email"),
          })}
          onSubmit={async (values, { resetForm, setFieldError }) => {
            const newsletterCollection = collection(
              getFirestore(),
              "newsletter",
            );
            const q = query(
              newsletterCollection,
              where("email", "==", values.email),
            );
            const subscribers = [];
            const newsletterSnap = await getDocs(q);

            newsletterSnap.forEach(doc => {
              subscribers.push(doc.data().email);
            });
            const isAlreadySignedUp = newsletterSnap.size !== 0;
            try {
              if (!isAlreadySignedUp) {
                await addDoc(newsletterCollection, {
                  email: values.email,
                  subscribedAt: new Date(),
                });
                setIsSubscribed(true);
                resetForm();
              } else {
                setFieldError("email", t("You're already subscribed"));
              }
            } catch (e) {
              setFieldError("email", t("An error occurred. Please try again."));
            }
          }}
        >
          {({ handleSubmit }) => (
            <Form className="md:w-1/3">
              <div className="flex items-end mb-3">
                <label className="w-full">
                  <Field
                    type="text"
                    name="email"
                    placeholder={t("Email")}
                    className={classNames(
                      whiteLabeler
                        ? `text-${name}-brand focus:ring-${name}-brand !rounded-l-${name}`
                        : "text-brand focus:ring-brand",
                      " pr-8 font-medium !rounded-r-none w-full",
                    )}
                  />
                </label>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={classNames(
                    whiteLabeler
                      ? `text-white rounded-r-${name} bg-${name}-brand border-${name}-brand`
                      : "text-brand rounded-r bg-gray-100 border-gray-100",
                    "py-3 px-4 border border-l-0 font-medium",
                  )}
                >
                  {t("Subscribe")}
                </button>
              </div>
              <ErrorMessage name="email" render={StyledErrorMessage} />
            </Form>
          )}
        </Formik>
        {isSubscribed && (
          <div className="flex text-gray-100">
            <p className="font-medium">
              {t("You've subscribed successfully!")}
            </p>{" "}
            <XCircleIcon
              className="w-4 cursor-pointer"
              onClick={() => setIsSubscribed(false)}
            />
          </div>
        )}
      </div>
    </section>
  );
}
