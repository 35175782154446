import React, { useContext } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import * as Yup from "yup";
import { updateConsultation } from "../../../../database/consultations";
import { useParams } from "react-router-dom";
import { ConsultationContext } from "../../../../contexts";
import StyledForm from "../../../../components/StyledForm";
import CurrencyField from "../../../../components/CurrencyField";
import { deleteField } from "firebase/firestore";

export default function IncomeForm({
  customerID,
  onSubmit,
  spendingPercentage,
}) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const consultation = useContext(ConsultationContext);

  const { income } = consultation?.budgets?.[customerID] || {};

  const incomeSchema = Yup.object().shape({
    type: Yup.string().required(t("Required")),
    gross: Yup.string().required(t("Required")),
    net: Yup.string().required(t("Required")),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          type: "",
          gross: "",
          net: "",
          ...income,
        }}
        validationSchema={incomeSchema}
        onSubmit={async values => {
          const gross = `budgets.${customerID}.income.gross`;
          const net = `budgets.${customerID}.income.net`;
          const type = `budgets.${customerID}.income.type`;
          const requirements = `budgets.${customerID}.requirements`;
          const secondPillar = `provisions.${customerID}.secondPillar`;
          await updateConsultation(consultationID, {
            [gross]: values.gross,
            [net]: values.net,
            [type]: values.type,
            [requirements]: spendingPercentage * values.gross,
            // Unemployed people don't have a second pillar
            ...(values.type === "not-employed" && {
              [secondPillar]: deleteField(),
            }),
          });
          return onSubmit ? onSubmit() : true;
        }}
      >
        {({ dirty }) => (
          <StyledForm dirty={dirty}>
            <InputGroup as="select" label={t("Employment type")} name="type">
              <option value="">{t("Please choose")}</option>
              <option value="self-employed">{t("Self-employed")}</option>
              <option value="not-employed">{t("Not employed")}</option>
              <option value="employed">{t("Employed")}</option>
            </InputGroup>
            <InputGroup
              name="net"
              component={CurrencyField}
              label={t("Net Income")}
              description={t(
                "This is the amount that ends up in your account.",
              )}
            />
            <InputGroup
              name="gross"
              component={CurrencyField}
              label={t("Gross Income")}
              description={t(
                "This is the amount that is shown in your work contract.",
              )}
            />
          </StyledForm>
        )}
      </Formik>
    </>
  );
}
